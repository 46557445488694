<template>
  <div v-if="stripeAccount">
    <b-alert :show="failToLoadPayouts" variant="danger" dismissible fade>
      {{ errorMessage }}
    </b-alert>
    <b-table
      show-empty
      id="table"
      class="payouts-table"
      striped
      sort-icon-left
      :fields="table.fields"
      :per-page="table.perPage"
      :current-page="table.currentPage"
      :items="items"
      :sort-by.sync="table.sortBy"
      :sort-desc.sync="table.sortDesc"
    >
      <template v-slot:table-busy>
        <div class="text-center">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template v-slot:cell(userFirstName)="row"> {{ row.item.userFirstName }} {{ row.item.userLastName }} </template>
    </b-table>
    <b-pagination
      v-model="table.currentPage"
      :total-rows="table.rows"
      :per-page="table.perPage"
      aria-controls="table"
      pills
      align="center"
      prev-text="Prev"
      next-text="Next"
      hide-goto-end-buttons
    >
    </b-pagination>
  </div>
</template>

<script>
import OrganizationServiceV2 from '@/lib/organization-service-v2';

export default {
  props: ['organization', 'stripeAccount', 'newPayout'],
  data() {
    return {
      table: {
        isBusy: false,
        fields: [
          {
            key: 'createdAt',
            label: 'Time Initiated',
            sortable: true,
            formatter: (value) => {
              return this.formatDateTime(this.parseISO(value));
            }
          },
          {
            key: 'arrivalDate',
            label: 'Estimated Deposit Date',
            sortable: true,
            formatter: (value) => {
              return this.formatDateTime(this.parseISO(value));
            }
          },
          {
            key: 'amountCents',
            label: 'Amount',
            sortable: false,
            formatter: (value) => {
              return this.formatCurrency(value / 100);
            }
          },
          {
            key: 'userFirstName',
            label: 'User',
            sortable: false
          }
        ],
        rows: 8,
        currentPage: 1,
        perPage: 8,
        sortBy: 'payoutDate',
        sortDesc: true,
        cursorMap: {}
      },
      failToLoadPayouts: false,
      errorMessage: false
    };
  },
  watch: {
    newPayout: function () {
      this.refreshTable();
    }
  },
  methods: {
    refreshTable() {
      this.$root.$emit('bv::refresh::table', 'table');
      this.$emit('refreshed');
    },
    async items() {
      try {
        if (this.organization.stripeConnectedAccountId) {
          const params = {
            provider: this.stripeAccount.provider,
            limit: this.table.perPage,
            fromCursor: this.table.cursorMap[this.table.currentPage - 1]
          };

          const payoutList = await OrganizationServiceV2.retrievePayouts(
            this.stripeAccount.id,
            this.stripeAccount.provider,
            params
          );

          // hacks ahead - the stripe transaction list only supports cursor-based pagination and doesn't expose the total rows up front

          // if we've never seen this page and more transaction exist, increase the rows
          if (!this.table.cursorMap[this.table.currentPage] && payoutList.hasNext) {
            this.table.rows += this.table.perPage;
          }

          // maintains a map for the page number to the cursor so we can navigate backwards through pages
          this.table.cursorMap[this.table.currentPage] = payoutList.cursor;

          return payoutList.payouts;
        }

        return [];
      } catch (error) {
        this.failToLoadPayouts = true;
        this.errorMessage = error.response ? error.response.data.errors[0].message : error;
      }
    }
  }
};
</script>
