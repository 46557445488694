<template>
  <div>
    <b-alert v-model="success.model" variant="success" dismissible fade>
      {{ success.text }}
    </b-alert>
    <b-alert v-model="alert.model" variant="danger" dismissible fade>
      {{ alert.text }}
    </b-alert>
    <div v-if="loading"><b-spinner /></div>
    <div v-else>
      <div v-if="organization && !organization.stripeConnectedAccountId">
        <b-spinner v-if="creatingStripeAccount" />
        <b-button v-else variant="outline-secondary" @click="createStripeAccount"> Setup Stripe Connect </b-button>
      </div>
      <b-badge variant="warning" class="pd-4" v-if="!stripeAccount">No Stripe Account</b-badge>
      <div v-else>
        <dl class="row">
          <dt class="col-sm-2">Status</dt>
          <dd class="col-sm-9">
            <b-badge :variant="accountStatusVariant">{{ accountStatusMessage }}</b-badge>
            <b-badge
              variant="warning"
              v-if="
                showStripeFutureRequirements &&
                !accountRejected &&
                stripeNextRequirementsDeadline &&
                stripeRequiresFutureInformation
              "
              v-b-tooltip.hover="`Due: ${stripeNextRequirementsDeadline}`"
              class="ml-3"
            >
              Restricted Soon
            </b-badge>
          </dd>
        </dl>

        <dl class="row mt-2" v-if="stripeRequiresInformation || stripeRequiresBankAccount">
          <dt class="col-sm-2">Additional Information</dt>
          <dd class="col-sm-9">
            <a v-if="stripeRequiresInformation" href="#" class="badge badge-warning mr-1" @click.stop="editInStripe">
              Stripe requires additional information
            </a>
            <a v-if="stripeRequiresBankAccount" href="#" class="badge badge-warning" v-b-modal.edit-stripe-bank-modal>
              Bank account required
            </a>
          </dd>
        </dl>

        <b-row class="mt-2">
          <b-col>
            <b-spinner v-if="editingInStripe" />
            <b-button v-else variant="outline-secondary" @click="editInStripe" class="mb-4">Edit Details</b-button>
            <b-button v-b-modal="'edit-stripe-bank-modal'" variant="outline-secondary" class="d-inline ml-2 mb-4">
              Edit Bank Account
            </b-button>
            <StripeBankAccountModal modalId="modal" :stripeAccount="stripeAccount" :organization="organization" />
          </b-col>
        </b-row>

        <dl class="row mt-2">
          <dt class="col-sm-2">Account ID</dt>
          <dd class="col-sm-9">
            {{ stripeAccount.id }}
          </dd>
        </dl>

        <dl class="row mt-2">
          <dt class="col-sm-2">Account Name</dt>
          <dd class="col-sm-9">
            {{ stripeAccount.name }}
          </dd>
        </dl>

        <dl class="row mt-2">
          <dt class="col-sm-2">Bank Name</dt>
          <dd class="col-sm-9">
            {{ stripeAccount.bankAccount ? stripeAccount.bankAccount.bankName : '' }}
          </dd>
        </dl>

        <dl class="row mt-2">
          <dt class="col-sm-2">Account Number</dt>
          <dd class="col-sm-9">
            {{ stripeAccount.bankAccount ? `*****${stripeAccount.bankAccount.last4}` : '' }}
          </dd>
        </dl>

        <dl class="row mt-2">
          <dt class="col-sm-2">Currency</dt>
          <dd class="col-sm-9">
            {{ stripeAccount.currency ? stripeAccount.currency.toUpperCase() : '' }}
          </dd>
        </dl>

        <hr class="mt-5" />
        <h2>Stripe Fees</h2>

        <dl class="row">
          <dt class="col-sm-2">Transaction Fee</dt>
          <dd class="col-sm-9">
            {{ formatCurrency(organization.merchantTxFeeCents / 100) }}
          </dd>
        </dl>

        <dl class="row mt-2">
          <dt class="col-sm-2">Percent Fee</dt>
          <dd class="col-sm-9">{{ organization.merchantTxFeePercent }}%</dd>
        </dl>

        <hr class="mt-5" />
        <h2>Account Owner</h2>

        <dl class="row">
          <dt class="col-sm-2">First Name</dt>
          <dd class="col-sm-9">
            {{ stripeAccount.firstName }}
          </dd>
        </dl>

        <dl class="row mt-2">
          <dt class="col-sm-2">Last Name</dt>
          <dd class="col-sm-9">
            {{ stripeAccount.lastName }}
          </dd>
        </dl>

        <dl class="row mt-2">
          <dt class="col-sm-2">Email</dt>
          <dd class="col-sm-9">
            {{ stripeAccount.email }}
          </dd>
        </dl>

        <dl class="row mt-2">
          <dt class="col-sm-2">Phone</dt>
          <dd class="col-sm-9">
            {{ stripeAccount.phone }}
          </dd>
        </dl>

        <dl class="row mt-2">
          <dt class="col-sm-2">City</dt>
          <dd class="col-sm-9">
            {{ stripeAccount.city }}
          </dd>
        </dl>

        <dl class="row mt-2">
          <dt class="col-sm-2">{{ this.stripeAccount.country === 'CA' ? 'Province' : 'State' }}</dt>
          <dd class="col-sm-9">
            {{ stripeAccount.province }}
          </dd>
        </dl>

        <dl class="row mt-2">
          <dt class="col-sm-2">Address</dt>
          <dd class="col-sm-9">
            {{ stripeAccount.address }}
          </dd>
        </dl>

        <dl class="row mt-2">
          <dt class="col-sm-2">{{ this.stripeAccount.country === 'CA' ? 'Postal Code' : 'ZIP Code' }}</dt>
          <dd class="col-sm-9">
            {{ stripeAccount.postal }}
          </dd>
        </dl>

        <dl class="row mt-2">
          <dt class="col-sm-2">Country</dt>
          <dd class="col-sm-9">
            {{ stripeAccount.country }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import StripeBankAccountModal from '@/components/StripeBankAccountModal.vue';
import OrganizationServiceV2 from '@/lib/organization-service-v2';

import { unleashFeatureEnabled, UnleashKeys } from '@/lib/unleash';

export default {
  name: 'MerchantStripe',
  components: { StripeBankAccountModal },
  props: ['organization'],
  data() {
    return {
      loading: false,
      creatingStripeAccount: false,
      editingInStripe: false,
      stripeAccount: null,
      requirements: {},
      futureRequirements: {},
      showStripeFutureRequirements: false,
      alert: {
        text: '',
        model: false
      },
      success: {
        text: '',
        model: false
      }
    };
  },

  async mounted() {
    this.loadStripeAccount();
    this.showStripeFutureRequirements = await unleashFeatureEnabled(UnleashKeys.StripeFutureRequirements);
  },

  computed: {
    stripeRequirements() {
      const requirementList = [
        ...this.requirements.currently_due,
        ...this.requirements.past_due,
        ...this.requirements.eventually_due
      ];
      return new Set(requirementList);
    },

    stripeFutureRequirements() {
      // backwards compatibility with api
      if (!this.futureRequirements) return [];

      const requirementList = [
        ...this.futureRequirements.currently_due,
        ...this.futureRequirements.past_due,
        ...this.futureRequirements.eventually_due
      ];
      return new Set(requirementList);
    },

    stripeNextRequirementsDeadline() {
      const deadline = this.requirements.current_deadline || this.futureRequirements?.current_deadline;

      if (deadline) {
        return new Date(parseInt(`${deadline}000`)).toLocaleDateString();
      }

      return null;
    },

    stripeRequiresInformation() {
      return (
        (this.stripeRequirements.size > 0 ||
          (this.showStripeFutureRequirements && this.stripeFutureRequirements.size > 0)) &&
        !(this.stripeRequirements.size === 1 && this.stripeRequirements.has('external_account'))
      );
    },

    stripeRequiresFutureInformation() {
      return this.stripeFutureRequirements.size > 0;
    },

    stripeRequiresBankAccount() {
      return this.stripeRequirements.has('external_account');
    },

    accountComplete() {
      return (
        this.requirements.currently_due.length === 0 &&
        this.requirements.eventually_due.length === 0 &&
        this.requirements.past_due.length === 0
      );
    },

    accountEnabled() {
      return this.requirements.currently_due.length === 0 && this.requirements.eventually_due.length > 0;
    },

    accountRestricted() {
      return this.requirements.currently_due.length > 0;
    },

    accountPending() {
      return this.requirements.pending_verification.length > 0;
    },

    accountRejected() {
      return this.requirements.disabled_reason?.includes('rejected');
    },

    accountStatusVariant() {
      const statusMap = {
        Rejected: 'secondary',
        Complete: 'success',
        Enabled: 'warning',
        Restricted: 'danger',
        Pending: 'info',
        Error: 'dark'
      };

      return statusMap[this.accountStatusMessage];
    },

    accountStatusMessage() {
      if (this.accountRejected) {
        return 'Rejected';
      }
      if (this.accountComplete) {
        return 'Complete';
      }
      if (this.accountEnabled) {
        return 'Enabled';
      }
      if (this.accountRestricted) {
        return 'Restricted';
      }
      if (this.accountPending) {
        return 'Pending';
      }

      return 'Error';
    }
  },

  methods: {
    async editInStripe() {
      this.editingInStripe = true;

      try {
        const response = await OrganizationServiceV2.createAccountLink(
          this.organization.stripeConnectedAccountId,
          this.organization.id,
          this.organization.country
        );
        window.location.href = response.accountLink;
      } catch (error) {
        this.editingInStripe = false;
        this.handleError(error);
      }
    },

    async createStripeAccount() {
      this.creatingStripeAccount = true;

      try {
        const response = await OrganizationServiceV2.createAccount('stripe');
        this.organization.stripeConnectedAccountId = response.accountId;
        this.editInStripe();
      } catch (error) {
        this.handleError(error);
        this.creatingStripeAccount = false;
      }
    },

    async loadStripeAccount() {
      if (this.organization.stripeConnectedAccountId) {
        this.loading = true;
        try {
          const account = await OrganizationServiceV2.retrieveAccount(this.organization.stripeConnectedAccountId);

          this.stripeAccount = {
            id: account.id,
            name: account.name,
            country: account.country,
            city: account.city,
            address: account.address,
            postal: this.formatPostal(account.postal),
            province: account.province,
            email: account.email,
            active: account.active,
            provider: account.provider,
            firstName: account.firstName,
            lastName: account.lastName,
            phone: this.formatPhone(account.phone),
            availableBalance: this.formatCurrency(account.availableBalanceCents / 100),
            pendingBalance: this.formatCurrency(account.pendingBalanceCents / 100),
            providerData: account.providerData,
            currency: account.currency,
            bankAccount: account.bankAccount
          };

          this.requirements = this.stripeAccount.providerData.requirements;
          this.futureRequirements = this.stripeAccount.providerData.futureRequirements;
        } catch (error) {
          this.handleError(error);
        }
        this.loading = false;
      }
    },

    handleSuccess(text) {
      this.success.text = text;
      this.success.model = true;
    },

    handleError(error) {
      this.alert.text = error.response ? error.response.data.errors[0].message : error;
      this.alert.model = true;
    }
  }
};
</script>
