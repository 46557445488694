<template>
  <b-modal
    :id="'edit-stripe-bank-' + modalId"
    title="Edit Stripe Bank"
    v-model="modalShow"
    @show="resetForm"
    @hidden="resetForm"
    @ok="handleOk"
    no-close-on-backdrop
    size="md"
  >
    <form @submit.stop.prevent="onSubmit">
      <Alert v-if="errorMessage" variant="red" icon="exclamation">
        Failed to update bank account: {{ errorMessage }}
      </Alert>
      <b-form-group
        label="Account Holder Name"
        label-for="input-account-holder-name"
        :invalid-feedback="veeErrors.first('input-account-holder-name')"
        label-class="mb-0"
      >
        <b-form-input
          name="input-account-holder-name"
          v-model="accountHolderName"
          v-validate="{ required: true }"
          :state="validateState('input-account-holder-name')"
          aria-describedby="input-account-holder-name"
          data-vv-as="account holder name"
          trim
        />
      </b-form-group>

      <b-form-group
        label="Account Holder Type"
        label-for="input-account-holder-type"
        :invalid-feedback="veeErrors.first('input-account-holder-type')"
        label-class="mb-0"
      >
        <b-form-select
          name="input-account-holder-type"
          v-model="accountHolderType"
          v-validate="{ required: true }"
          :options="accountTypeOptions"
          :state="validateState('input-account-holder-type')"
          aria-describedby="input-account-holder-type"
          data-vv-as="account holder type"
          trim
        />
      </b-form-group>

      <b-form-group
        v-if="stripeAccount.country === 'CA'"
        label="Institution Number"
        label-for="input-institution-number"
        :invalid-feedback="veeErrors.first('input-institution-number')"
        label-class="mb-0"
      >
        <b-form-input
          name="input-institution-number"
          placeholder="000"
          v-model="institutionNumber"
          v-validate="{ required: true, digits: 3 }"
          :state="validateState('input-institution-number')"
          aria-describedby="input-institution-number"
          data-vv-as="institution number"
          trim
        />
      </b-form-group>

      <b-form-group
        v-if="stripeAccount.country === 'CA'"
        label="Transit Number"
        label-for="input-transit-number"
        :invalid-feedback="veeErrors.first('input-transit-number')"
        label-class="mb-0"
      >
        <b-form-input
          name="input-transit-number"
          placeholder="12345"
          v-model="transitNumber"
          v-validate="{ required: true, digits: 5 }"
          :state="validateState('input-transit-number')"
          aria-describedby="input-transit-number"
          data-vv-as="transit number"
          trim
        />
      </b-form-group>

      <b-form-group
        v-if="stripeAccount.country === 'US'"
        label="Routing Number"
        label-for="input-routing-number"
        :invalid-feedback="veeErrors.first('input-routing-number')"
        label-class="mb-0"
      >
        <b-form-input
          name="input-routing-number"
          placeholder="000000000"
          v-model="routingNumber"
          v-validate="{ required: true, digits: 9 }"
          :state="validateState('input-routing-number')"
          aria-describedby="input-routing-number"
          data-vv-as="routing number"
          trim
        />
      </b-form-group>

      <b-form-group
        label="Account Number"
        label-for="input-account-number"
        :invalid-feedback="veeErrors.first('input-account-number')"
        label-class="mb-0"
      >
        <b-form-input
          name="input-account-number"
          v-model="accountNumber"
          v-validate="{ required: true, min: 7, max: 14, numeric: true }"
          :state="validateState('input-account-number')"
          aria-describedby="input-account-number"
          data-vv-as="account number"
          trim
        />
      </b-form-group>
    </form>
  </b-modal>
</template>
<!-- todo add ts -->
<script>
import StripeService from '@/lib/stripe';
import OrganizationServiceV2 from '@/lib/organization-service-v2';
import Alert from '@/components/ui/Alert';

export default {
  name: 'StripeBankAccountModal',
  props: {
    modalId: {
      type: String
    },
    stripeAccount: {
      type: Object
    }
  },
  components: {
    Alert
  },
  data() {
    return {
      modalShow: false,
      updateBankAccountFailed: false,
      errorMessage: null,
      institutionNumber: null,
      transitNumber: null,
      routingNumber: null,
      accountNumber: null,
      accountHolderName: null,
      accountHolderType: null,
      accountTypeOptions: [
        { value: 'company', text: 'Company' },
        { value: 'individual', text: 'Individual' }
      ]
    };
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },

    resetForm() {
      this.updateBankAccountFailed = false;
      this.errorMessage = null;

      // Canadian accounts
      this.institutionNumber = null;
      this.transitNumber = null;

      // US accounts
      this.routingNumber = null;

      this.accountNumber = null;
      this.accountHolderName = null;
      this.accountHolderType = null;

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },

    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },

    async onSubmit() {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }

      try {
        const stripe = await StripeService.retrieveStripePlatformAccount(
          this.stripeAccount.country,
          this.stripeAccount.id
        );
        const createTokenResponse = await stripe.createToken('bank_account', {
          country: this.stripeAccount.country,
          currency: this.stripeAccount.currency,
          // eslint-disable-next-line @typescript-eslint/camelcase
          routing_number:
            this.stripeAccount.country === 'CA'
              ? `${this.transitNumber}-${this.institutionNumber}`
              : this.routingNumber,
          // eslint-disable-next-line @typescript-eslint/camelcase
          account_number: this.accountNumber,
          // eslint-disable-next-line @typescript-eslint/camelcase
          account_holder_name: this.accountHolderName,
          // eslint-disable-next-line @typescript-eslint/camelcase
          account_holder_type: this.accountHolderType
        });
        if (createTokenResponse.error) {
          this.errorMessage = createTokenResponse.error.message;
          return;
        }

        await OrganizationServiceV2.updateAccount(this.stripeAccount.id, {
          provider: 'stripe',
          bankToken: createTokenResponse.token.id
        });

        this.modalShow = false;
        this.$router.go();
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
    }
  }
};
</script>

<style scoped></style>
