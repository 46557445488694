import { loadStripe } from '@stripe/stripe-js';

import config from '@/config';
import ConfigServiceV2 from '@/lib/config-service-v2';

export default {
  /**
   * Retrieves Stripe Platform Account based on the `countryCode` and `stripeAccountId` provided.
   *
   * Due to the fact that there're exist some US organizations that use Canadian Stripe Platform Account,
   * we have check if the Stripe account id belongs to the migrating organization.
   *
   * Once the migration is complete, we no longer have to perform the check, and a Stripe Platform Account
   * can be retrieved simply based on the `countryCode` argument.
   * @param {*} countryCode `"US"` or `"CA"`.
   */
  async retrieveStripePlatformAccount(countryCode, stripeAccountId) {
    let actualCountryCode;
    switch (countryCode) {
      case 'CA':
        actualCountryCode = 'CA';
        break;
      case 'US':
        if (!stripeAccountId) {
          actualCountryCode = 'US';
          break;
        }

        // eslint-disable-next-line no-case-declarations
        const config = await ConfigServiceV2.retrieveConfig();

        if (!config) {
          actualCountryCode = 'US';
          break;
        }

        // eslint-disable-next-line no-case-declarations
        const migratingAccountIds = config.config.migratingStripeAccountIds;
        if (!migratingAccountIds) {
          actualCountryCode = 'US';
          break;
        }

        actualCountryCode = migratingAccountIds.includes(stripeAccountId) ? 'CA' : 'US';
    }

    switch (actualCountryCode) {
      case 'CA':
        return await loadStripe(config.STRIPE_PUBLIC_CA_KEY, { apiVersion: '2022-11-15' });
      case 'US':
        return await loadStripe(config.STRIPE_PUBLIC_US_KEY, { apiVersion: '2022-11-15' });
    }
  }
};
